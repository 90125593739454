<template>
  <Modal v-model="showModal" :mask-closable="false" width="1500px">
    <div slot="header">{{showLang('timetable.edit.lamp.chronometer')}}</div>
    <Form ref="form" :model="form" :label-width="70">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-bottom: 5px;width: 400px">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder=""></Input>
      </FormItem>
      <FormItem :label-width="0">
        <table class="set-table" v-if="form.content">
          <tr>
            <td>{{showLang('com.date.period')}}</td>
            <td>{{showLang('com.date.duration')}}(min)</td>
            <td>{{showLang('com.lighting.op')}}(%)</td>
            <td>{{showLang('com.lighting.oc')}}(%)</td>
            <td>{{showLang('com.but.linkageLight')}}(%)</td>
          </tr>

          <template v-for="m in 6">
            <tr :key="m" v-if="form.content[m-1]" style="height:60px;">
              <td>{{showLang('com.date.period')}}{{m}}</td>
              <td>
                <Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.content[m-1].min" placeholder=""></Input>
              </td>
              <td><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.content[m-1][`ch1`]" placeholder=""></Input></td>
              <td><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.content[m-1][`c1`]" placeholder=""></Input></td>
              <td><Input class="min-width" ref="min" type="number" :min="0" :max="100" number v-model="form.content[m-1][`un1`]" placeholder=""></Input></td>
            </tr>
          </template>
        </table>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalLightTable',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      tabs: [],
      isAdd: false,
      index: -1,
      form: {
        id: 0,
        name: '',
        content: {},
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.index = this.item.index;
        this.isAdd = this.item.isAdd;
        if (!this.item.isAdd) {
          this.form.id = this.item.data.id;
          this.getTableContent();
        } else {
          this.form.id = 0;
          this.form.name = '';
          this.form.type = 1;
          this.form.content = {
            0: { min: '0', ch1: 0, c1: 0, un1: 0 },
            1: { min: '0', ch1: 0, c1: 0, un1: 0 },
            2: { min: '0', ch1: 0, c1: 0, un1: 0 },
            3: { min: '0', ch1: 0, c1: 0, un1: 0 },
            4: { min: '0', ch1: 0, c1: 0, un1: 0 },
            5: { min: '0', ch1: 0, c1: 0, un1: 0 },
          }
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  mounted: function () {
  },
  methods: {
    getTableContent: function () {
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight6Detail`, { id: this.form.id }).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          return;
        }
        this.form.type = res.data.type;
        this.form.name = res.data.name;
        this.$set(this.form, 'content', res.data.content);
      })
    },
    ok: async function () {
      this.form.name = this.form.name.trim();
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      for (let key in this.form.content) {
        let item = this.form.content[key];
        let kidx = parseInt(key);
        if (item.min < 0 || item.min > 1440) {
          // this.$Message.warning(`时段${kidx + 1}的分钟必须在 0 ~ 1440 之间`);
          this.$Message.warning(this.showLang('save.time.minute.between', kidx + 1, 0, 1440));
          return;
        }
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/plan/SaveLight6Detail`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.set-table tr td {
  border: solid 1px gray;
  text-align: center;
  white-space: nowrap;
}
.set-table {
  width: 100%;
}
.time-span {
  display: flex;
}
.min-width {
  width: 70px;
  flex: none;
  margin: 0;
}
.ch-width {
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span {
  width: 25px;
  flex: none;
}
.ch-width div {
  width: 40px;
  flex: auto;
}
</style>