<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">{{showLang('timetable.edit.lamp.punctual')}}</div>
    <Form ref="form" :model="form" :label-width="70">
      <FormItem prop="name" :label="showLang('com.tab.title')" style="margin-bottom: 5px;width: 300px">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder=""></Input>
      </FormItem>
      <FormItem :label="showLang('com.look.data')">
        <DatePicker type="date" v-model="dateDay" format="MM-dd" :placeholder="showLang('com.date.start')" style="width: 230px" @on-change='dateDayChange'></DatePicker>
        {{showLang('com.date.sunrise')}}：<span class='red'>{{dateTime.time1}}</span>
        {{showLang('com.date.sunset')}}：<span class='red'>{{dateTime.time2}}</span>
      </FormItem>
      <FormItem :label-width="0">
        <table class="set-table" v-if="form.content" :key="formIndex">
          <tr>
            <td style="width:70px">时段</td>
            <td style="width:200px">模式</td>
            <td>时间</td>
            <td style="width:200px">亮度(%)</td>
            <td style="width:200px">联动(%)</td>
          </tr>
          <template v-for="el in 6">
            <tr :key="el" class="mt5">
              <td style="height:60px">时段{{el}}</td>
              <td>
                <Select v-model="form.content[el-1].mode" style="width:80px">
                  <Option :value="0">未启用</Option>
                  <Option :value="1">经纬度</Option>
                  <Option :value="2">时钟</Option>
                </Select>
              </td>
              <td>
                <span v-if="form.content[el-1].mode==1">
                  <Select v-model="form.content[el-1].on" style="width:90px">
                    <Option :value="0">日落时间</Option>
                    <Option :value="1">日出时间</Option>
                  </Select>
                </span>
                <span v-if="form.content[el-1].mode==2" class="ml10">
                  <TimePicker style="width:180px" v-model="minList[el-1]" format="HH:mm" :placeholder="showLang('com.date.select.time')" :key="el"></TimePicker>
                </span>
                <span v-if="form.content[el-1].mode==1" class="ml10">
                  <Select v-model="form.content[el-1].off" style="width:80px">
                    <Option :value="0">无偏移</Option>
                    <Option :value="1">延迟</Option>
                    <Option :value="2">提早</Option>
                  </Select>
                  <span v-if="form.content[el-1].off!=0" class="ml10">
                    偏移量<Input type="number" style="width:60px" :min="0" :max="100" number v-model="form.content[el-1].interval" placeholder="" />分
                  </span>
                </span>
              </td>
              <td>
                <Input type="number" style="width:60px" :min="0" :max="100" number v-model="form.content[el-1].bright" placeholder="" />
              </td>
              <td>
                <Input type="number" style="width:60px" :min="0" :max="100" number v-model="form.content[el-1].union" placeholder="" />
              </td>
            </tr>
          </template>
        </table>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.close')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters,mapState } from 'vuex'

// import TableBox from './TableBox'
export default {
  name: 'ModalLightTable',
  components: {
    // TableBox
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      tabs: [],
      minList: ['00:00', '00:00', '00:00', '00:00', '00:00', '00:00',],
      isAdd: false,
      index: -1,
      formIndex: 0,
      rowIndex: 2,
      checkboxList: [false, false, false, false, false, false],
      form: {
        id: 0,
        name: '',
        type: 0,
        content: {
          0: { "mode": 1, "on": 0, "off": 0, "interval": 5, "bright": 100, "union": 100 },
          1: { "mode": 1, "on": 0, "off": 0, "interval": 5, "bright": 100, "union": 100 },
          2: { "mode": 1, "on": 0, "off": 0, "interval": 5, "bright": 100, "union": 100 },
          3: { "mode": 1, "on": 0, "off": 0, "interval": 5, "bright": 100, "union": 100 },
          4: { "mode": 1, "on": 0, "off": 0, "interval": 5, "bright": 100, "union": 100 },
          5: { "mode": 1, "on": 0, "off": 0, "interval": 5, "bright": 100, "union": 100 },
        },
      },
      dateDay: '01-01',
      dateTime: {
        time1: '',
        time2: '',
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        this.index = this.item.index;
        this.isAdd = this.item.isAdd;
        if (!this.item.isAdd) {
          this.getTableContent();
          this.form.id = this.item.data.id;
        } else {
          this.form.id = 0;
          this.form.name = '';
        }
        let currentDate = new Date();
        this.dateDay = `${currentDate.getMonth() + 1}-${currentDate.getDate()}`
        this.dateDayChange();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState("auth", ["config",]),
  },
  mounted: function () {
  },
  methods: {
    dateDayChange() {
      let year = new Date().getFullYear();
      let day = new Date(this.dateDay).getDate();
      let month = new Date(this.dateDay).getMonth() + 1;
      let datea = new Date(`${year}-${month}-${day}`);
      let riseAndSet = datea.sunRiseAndSet(this.config.lat, this.config.lng);
      this.dateTime.time1 = riseAndSet.rise;
      this.dateTime.time2 = riseAndSet.set;
    },
    changeCheckbox(num) {
      if (!this.checkboxList[num - 1]) {
        return false;
      }
      for (let index = 0; index < this.form.content.length; index++) {
        if (index % 6 == (num - 1)) {
          this.form.content[index] = JSON.parse(JSON.stringify(this.form.content[num - 1]));
        }
      }
      this.form.content.push({});
      this.form.content.pop();
    },
    numIndex(m, n) {
      let num = 0;
      num = m + 6 * (n - 1) - 1
      return num;
    },
    getTableContent: function () {
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLight8Detail`, { id: this.item.data.id }).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          return;
        }
        this.form.type = res.data.type;
        this.form.name = res.data.name;
        let data = res.data.content
        for (let key in data) {
          if (data[key].mode == 2) {
            let a = Math.floor(data[key].interval / 60);
            let b = Math.floor(data[key].interval % 60);
            this.minList[key] = a + ':' + b;
          }
        }
        this.$set(this.form, 'content', data);
      })
    },
    ok: async function () {
      this.form.name = this.form.name.trim();
      if (!this.form.name || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('com.save.err.len.name', 20));
        return;
      }
      this.loading = true;
      for (let key in this.form.content) {
        if (this.form.content[key].mode == 2) {
          let arr = this.minList[key].split(":");
          this.form.content[key].interval = arr[0] * 60 + arr[1] * 1;
        }
      }
      this.$axios.post(`//${this.domains.trans}/station/plan/SaveLight8Detail`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.showModal = false;
          this.$emit('saved');
          this.$Message.success(this.showLang('com.ins.success'));
        }
      });
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.set-table tr td {
  border: solid 1px gray;
  text-align: center;
  white-space: nowrap;
}
.tableBox {
  width: 243px;
  height: 102px;
}
.set-table {
  width: 100%;
}
.time-span {
  display: flex;
}
.min-width {
  width: 90px;
  flex: none;
  margin: 0;
}
.ch-width {
  width: 120px;
  flex: none;
  margin: 0 10px;
  display: flex;
}
.ch-width span {
  width: 25px;
  flex: none;
}
.ch-width div {
  width: 40px;
  flex: auto;
}
.red {
  color: #f00;
  cursor: pointer;
}
</style>